import React, { useEffect,useContext,useState } from "react";
import Context from '../_helpers/context';
const lang = require('./lang/lang.json')

export default function Username(props) {

  const context = useContext(Context)
  const locale = context.lang

  function handleInputChange(target) {
    if(!target.currentTarget.value){
        context.user_form.username = null
    }else{
         var value = target.currentTarget.value;
        context.user_form.username = value
    }
       
   
    
  }


  

  return (
    <div >
        <input type="search" placeholder="Username" onChange={(e) => handleInputChange(e)} />
        { props.username && (<div className="errore">{lang[locale].errore_username}</div>)}
    </div>
  );
}